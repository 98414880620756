<template>
<div>
  <navbar></navbar>
  <SearchBarRooms :filters="filters" :view="'rooms'" :min="min" :max="max"></SearchBarRooms>
  <v-layout wrap>
    <v-flex xs12 sm8 class="">
      <div class="">
        <!-- loading: {{loading}} -->
        <!-- rooms: {{roomsLoaded}}
        apt: {{apartmentsLoaded}}
        studio: {{studiosLoaded}} -->
      </div>
      <Loading v-if="loading===true" :numberOfList="numberRooms"></Loading>
      <v-layout wrap v-else class="results-col">
        <v-flex xs12 class="px-3">
          <div class="" v-if="urgencyVal==3">
            <v-layout align-center>
              <v-flex shrink>
                <v-icon color="primary" class="pr-3">info</v-icon>
              </v-flex>
              <v-flex>
                <span class="font-weight-bold">15% of users arriving in {{filters.mid | month}} have already booked their rooms.</span>
                <br>We recommend to your book at least 6 months in advance
              </v-flex>
            </v-layout>
          </div>
          <div class="" v-if="urgencyVal==2" class="">
            <v-layout align-center>
              <v-flex shrink>
                <v-icon color="#ff4000" class="pr-3" large>access_alarm</v-icon>
              </v-flex>
              <v-flex>
                <span class="font-weight-bold">More than 35% of users arriving in {{filters.mid | month}} have already booked their rooms. </span>
                <br> We recommend booking your place as soon as possible.
              </v-flex>
            </v-layout>
          </div>
          <div class="" v-if="urgencyVal==1">
            <v-layout align-center>
              <v-flex shrink>
                <v-icon color="red" v-if="percAv<30" large class="pr-3">warning</v-icon>
                <v-icon color="#ff4000" v-else-if="percAv<65" large class="pr-3">access_alarm</v-icon>
                <v-icon color="primary" v-else="" class="pr-3">info</v-icon>
              </v-flex>
              <v-flex>
                <span class="font-weight-bold">Only {{percAv}}% of listings are still available for these dates.</span>
                <br>We recommend booking your place soon.
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-flex xs12 sm6 md4 class="pa-2" v-for="room in displayed" :key="room['.key']">
          <!-- <RoomCard :mid="filters.mid" :fx="fx" :currency="currency" :lat="center.lat" :lng="center.lng" :mod="filters.mod" @setDistance="setDistance(room,$event)" :room="room"></RoomCard> -->
        </v-flex>
        <v-flex xs12 class="no-results" v-if="this.displayed.length==0">
          <span class="no-results-text" v-if="loading==false">{{$t('resultsPage.noData1')}} <br>{{$t('resultsPage.noData2')}}</span>
          <!-- <v-btn color="primary" @click="resetFilters">{{$t('resultsPage.removeFilters')}}</v-btn> -->
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm4>
      <SearchMap :center="center" :rooms="filtered" :zoom="zoom" @changeVisibleRooms="setDisplayed">
      </SearchMap>
    </v-flex>

  </v-layout>
  <v-layout justify-center ma-2 wrap>
    <v-flex xs11 sm7 class="box">
      <Area :noButton="true" :area="area"></Area>
    </v-flex>
  </v-layout>
</div>
</template>

<script>
import Loading from '../shared/Loading.vue'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import SearchBarRooms from './SearchBarRooms.vue'
import RoomCard from './RoomCard.vue'
import Area from '../Area.vue'
import SearchMap from './SearchMap.vue'
import inside from 'point-in-polygon'

export default {
  name: 'Search',
  data: () => ({
    center: {
      lat: 25.0347833,
      lng: 121.5139323
    },
    zoom:12,
    min:8000,
    numberAvNow:0,
    max:18000,
    allRooms:[],
    filtered:[],
    displayed:[],
    roomsLoaded:false,
    studiosLoaded:false,
    apartmentsLoaded:false,
    filters:{
      locName:'',
      mid:"",
      mod:"",
      propType:"",
      bedType:"",
      price:"",
      orderBy:"",
      cLength:"",
    },
    area:"daanStudent",
    fx:0.029

  }),
  firebase() {
    return {
      rooms: {
        source:firebase.database().ref('rooms').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          this.roomsLoaded=true
          console.log(new Date(), 'rooms');
          this.matchRoomsApts()
        }

      },
      apartments: {
        source:firebase.database().ref('apartments').orderByChild('draft').equalTo(false),
        asObject:true,
        readyCallback:function(){
          this.apartmentsLoaded=true
          console.log(new Date(), 'apts');
          this.matchRoomsApts()
        }
      },
      studios: {
        source:firebase.database().ref('studios').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          console.log(new Date(),'studios');
          for (var i = 0; i < this.studios.length; i++) {
            this.studios[i].type='studio'
            this.studios[i].url="/studioDetails/"+this.studios[i]['.key']
            this.checkMinMax(this.studios[i])
            this.allRooms.push(this.studios[i])
          }
          this.studiosLoaded=true
          this.setRooms()
        }
      },
    }
  },
  methods: {
    hover(room){
      this.hoverRoom=room;
    },
    setDistance(room, payload){
      // console.log(room, payload);
      room.distance=payload
      let el = this.allRooms.find(it=>{
        return it['.key']==room['.key']
      })
      el.distance=payload
    },
    setCookie(cname, cvalue, exdays) {
      let d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      let expires = "expires=" + d.toUTCString()
      document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/"
    },
    getCookie(cname) {
      var name = cname + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        // console.||('COOKIE NUMBER :' + i)
        // console.||(c)
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    checkMinMax(list){
      if (list!=undefined && list.billing!=undefined){
        if (!isNaN(parseInt(list.billing.price)) && parseInt(list.billing.price)<this.min){
          this.min=Math.floor(parseInt(list.billing.price)/500) * 500
        } else if (!isNaN(parseInt(list.billing.price))&& parseInt(list.billing.price)>this.max) {
          this.max= Math.ceil(parseInt(list.billing.price)/500)*500
        }
      } else {
        // console.log(list,'this room doesnt have a billing');
      }

    },

    setDisplayed(payload){
      // SearchBarRooms('Set displayed', payload);
      this.displayed=payload
    },
    matchRoomsApts(){
      if (this.roomsLoaded && this.apartmentsLoaded){
        for (var i = 0; i < this.rooms.length; i++) {
          if (this.apartments[this.rooms[i].apartmentId]!=undefined) {
            this.rooms[i].type='room'
            this.rooms[i].general.location=this.apartments[this.rooms[i].apartmentId].general.location
            this.allRooms.push(this.rooms[i])
            this.checkMinMax(this.rooms[i])
          }
        }
        this.setRooms()
      }
    },
    setArea(location){

      console.log('SET AREA,');
      if (inside([location.lat,location.lng],[[25.009265, 121.493519],[25.022660, 121.513878],[25.007928, 121.532117],[24.995454, 121.530985]])){
        this.area="yonghe"
      } else if ( inside([location.lat,location.lng],[[25.022660, 121.513878],[25.007928, 121.532117],[25.011120, 121.537355],[25.034589, 121.516956],[25.037567, 121.507363],[25.030265, 121.504245]])){
        this.area='zhongzheng'
      } else if ( inside([location.lat,location.lng],[[25.011120, 121.537355],[25.034589, 121.516956],[25.033037, 121.553444],[25.011379, 121.563730]])){
        this.area="daanStudent"
      } else if ( inside([location.lat, location.lng],[[24.995454, 121.530985],[25.011120, 121.537355],[25.011379, 121.563730],[25.016621, 121.598156],[24.979995, 121.600270],[24.969820, 121.567915]])){
        this.area="wenshan"
      } else if ( inside([location.lat, location.lng],[[25.037619, 121.595279],[25.011379, 121.563730],[25.033037, 121.553444],[25.045204, 121.561669],[25.044696, 121.578818]])){
        this.area="xinyi"
      } else if ( inside([location.lat, location.lng],[[25.045043, 121.532872],[25.045204, 121.561669],[25.044696, 121.578818],[25.052327, 121.578807],[25.074372, 121.569722],[25.075832, 121.543495]])){
        this.area="songshan"
      } else if ( inside([location.lat, location.lng],[[25.045204, 121.561669],[25.033037, 121.553444],[25.034589, 121.516956],[25.045043, 121.532872]])){
        this.area="daanExpat"
      } else if ( inside([location.lat, location.lng],[[25.034589, 121.516956],[25.045043, 121.532872],[25.075832, 121.543495],[25.076026, 121.506507],[25.052189, 121.504726]])){
        this.area="zhongshan"
      } else if ( inside([location.lat, location.lng],[[25.075832, 121.543495],[25.074372, 121.569722],[25.065426, 121.616447],[25.101222, 121.611935],[25.091674, 121.542136]])){
        this.area="neihu"
      } else if ( inside([location.lat, location.lng],[[25.091674, 121.542136],[25.166770, 121.572119],[25.101222, 121.611935],[25.075832, 121.543495],[25.076026, 121.506507],[25.095602, 121.509428]])){
        this.area="shilin"
      } else if ( inside([location.lat, location.lng],[[25.095602, 121.509428],[25.166770, 121.572119],[25.209079, 121.561712],[25.125054, 121.458978]])){
        this.area="beitou"
      } else if ( inside([location.lat, location.lng],[[25.125054, 121.458978],[25.209079, 121.561712],[25.227988, 121.443948],[25.183033, 121.404265]])){
        this.area="tamsui"
      }
    },

    setApartments(){
      this.filtered=JSON.parse(JSON.stringify(this.allApts))
      // console.log(this.filtered);
      // console.log(this.allApts);
      //filter apts
    },
    setRooms(){
      // console.log('setRooms function')
      this.filtered=JSON.parse(JSON.stringify(this.allRooms))
      this.filterLists()
      //filter Rooms
    },
    setFilter(to){

      if (to.query.name!=undefined){
        this.filters.locName=to.query.name
      }

      if (to.query.lat!=undefined && isNaN(parseFloat(to.query.lat))!=true ){
        this.center.lat=parseFloat(to.query.lat)
        this.setArea(this.center)
      }
      if (to.query.lng!=undefined && isNaN(parseFloat(to.query.lng))!=true ){
        this.center.lng=parseFloat(to.query.lng)
        this.setArea(this.center)
      }
      if (moment(to.query.mid).isValid()){
        this.filters.mid = to.query.mid
      } else {
        this.filters.mid=""
      }
      if (moment(to.query.mod).isValid()){
        this.filters.mod = to.query.mod
      } else {
        this.filters.mod=""
      }
      // console.log('The price:', parseFloat(to.query.price));
      if (!isNaN(parseFloat(to.query.price))){
        this.filters.price = parseFloat(to.query.price)
      } else {
        this.filters.price = undefined
      }
      // SearchBarRooms(this.filters.price, 'the filter.price');
      if (!isNaN(parseFloat(to.query.nor))){
        this.filters.nor = parseFloat(to.query.nor)
      } else {
        this.filters.nor = undefined
      }
      this.filters.bedType = to.query.bedType
      this.filters.propType = to.query.propType
      this.filters.orderBy = to.query.orderBy
      this.filters.cLength=to.query.cLength
      this.filters.aptType = ""
      this.filters.nor=""
      this.filters.fts=JSON.parse(to.query.fts)
      this.setCookie('filters', this.filters, 30)
      if (this.loading==false){
        // console.log('SET FILTER FILTER');
        this.filterLists()
      }
    },
    filterLists(){
      this.filtered=JSON.parse(JSON.stringify(this.allRooms))
      console.log('FILTER');
      if (this.filters.mid!=="" && this.filters.mid!=undefined && moment(this.filters.mid).isValid()){
        this.filtered=this.filtered.filter(list => this.filterMid(list))
        this.numberAvNow=this.filtered.length
      }
      if (this.filters.price!="" && this.filters.price!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterPrice(list))
      }
      if (this.filters.bedType!="" && this.filters.bedType!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterBedType(list))
      }
      if (this.filters.propType!="" && this.filters.propType!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterPropType(list))
      }
      if (this.filters.cLength!="" && this.filters.cLength!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterCLength(list))
      }
      if (this.filters.fts!="" && this.filters.fts!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterFts(list))
      }
      this.orderBy(this.filters.orderBy)
    },
    filtersFts(list){
      for (var i = 0; i < this.filters.fts.length; i++) {
        this.filters.fts[i]
      }
    },
    filterMid(list){
      if (list!=undefined && moment(list.general.nextAvailability).isValid()){
        let lowLimit = moment(this.filters.mid).subtract(1, 'M').subtract(15, 'd')
        let topLimit = moment(this.filters.mid).add(1, 'M')
        let val=moment(list.general.nextAvailability)
        // console.log(lowLimit.format('LL'), topLimit.format('LL'), val.format('LL'));
        if (val.isBetween(lowLimit,topLimit, null, '[]')){
          return true
        } else {
          // console.log('false, coz not included');
          if (list.acceptSummer==true) {
            console.log('true', list);
            // console.log('moment(this.filters.mid).isBetween(moment(July 31),moment(November 1))',moment().month("July").date('31'),);
              if (moment(this.filters.mid).isBetween(moment().month("July").date('31'),moment().month("November").date('1'))  && moment(list.general.nextAvailability).isBefore(moment().month("September").date('1') )){
                return true
              } else if (moment(this.filters.mid).isBetween(moment().month("May").date('31'),moment().month("August").date('1')) && moment(list.general.nextAvailability).isAfter(moment().month("August").date('1')) && list.septBooked==true && list.summerBooked==false ) {
                return true
              } else {
                return false
              }
          } else {
            return false
          }
        }
      } else {
        // console.log('ffalse coz', list, moment(list.general.nextAvailability).isValid());
        return false
      }
    },
    filterPrice(list){
      // console.SearchBarRooms('item:' , list, list.billing.price, this.filters.price);
      if (list!=undefined && list.billing.price<=this.filters.price){
        return true
      } else {
        return false
      }
    },
    filterBedType(list){
      // console.SearchBarRooms('item:' , list, list.billing.price, this.filters.price);
      if (list!=undefined && list.featureList.bedType==this.filters.bedType){
        return true
      } else {
        return false
      }
    },
    filterPropType(list){

      if(list!=undefined){
        if(this.filters.propType=="privateBath"){
            if (list.type=="studio"){
              return  true
            } else if (this.checkPrivateBath(list)){
              return true
            } else {
              return false
            }
        } else if (list.type==this.filters.propType){
          return true
        } else {
          return false
        }
      }
    },
    filterCLength(list){
      if(list!=undefined){
        if (list.cValue==undefined){
          list.cValue=this.setContractValue(list)
        }
        if (list.cValue<=this.filters.cLength){
          return true
        } else {
          return false
        }
      }
    },

    setContractValue(listing){
      let contract=""
      if (listing.type=="room"){
        contract= this.apartments[listing.apartmentId].contractDetails.contractLength
      } else {
        contract= listing.contractDetails.contractLength
      }
      let cValue=0
      switch (contract) {
        case '1 month':
          cValue=1
          break;
        case '3 months':
          cValue=2
          break;
        case '5 months':
          cValue=3
          break;
        case '12 months':
          cValue=4
          break;
        case 'Open contract':
          cValue=5
          break;
        default:
          cValue=0
      }
      return cValue
    },
    checkPrivateBath(listing){
      for (var i = 0; i < listing.featureList.features.length; i++) {
        if (listing.featureList.features[i]=='privateBathroom'){
          return true
        }
      }
      return false
    },
    orderBy(order){
      console.log('orderBy: ',order);
      switch (order) {
        case 'MRA':
            this.filtered.sort((a,b) =>this.sortMRA(a,b))
          break;
        case 'priceLtH':
            this.filtered.sort((a,b) =>this.sortPLtH(a,b))
          break;
        case 'priceHtL':
            this.filtered.sort((a,b) =>this.sortPHtL(a,b))
          break;

        case 'distance':
            this.filtered.sort((a,b) =>this.sortDistance(a,b))
          break;
        case 'closestToMoveIn':
            this.filtered.sort((a,b) =>this.sortMid(a,b))
          break;
        case 'newest':
            this.filtered.sort((a,b) =>this.sortNew(a,b))
          break;
        case 'oldest':
            this.filtered.sort((a,b) =>this.sortOld(a,b))
          break;

        default:
          this.filtered.sort((a,b) =>this.sortMRA(a,b))

      }
    },
    sortMRA(a,b){
      if (a.general.score==undefined){
        // console.log('No score a', a);
        a.general.score=0
      } if (a.general.sortScore==undefined){
        a.general.sortScore=parseInt(a.general.score)-0.5+Math.random()
      } if (b.general.score==undefined){
        b.general.score=0
      } if (b.general.sortScore==undefined){
        b.general.sortScore=parseInt(b.general.score)-0.5+Math.random()
      } if(a.general.sortScore>b.general.sortScore){
        return -1
      }
      if(a.general.sortScore<b.general.sortScore){
        return 1
      }
      return 0

    },
    sortPHtL(a,b){
      if (typeof(a.billing.price)=='string'){
        // console.log('set a int');
          a.billing.price=parseInt(a.billing.price)
      }
      if (typeof(b.billing.price)=='string'){
        // console.log('set b int');
          b.billing.price=parseInt(b.billing.price)
      }
      if(a.billing.price>b.billing.price){
        return -1
      }
      if (a.billing.price<b.billing.price){
        return 1
      }
      return 0

    },
    sortPLtH(a,b){
      if (a.billing.price==undefined){
        a.billing.price=0
        // console.log('ERROR HERE ON A', a);
      }
      if (b.billing.price==undefined){
        b.billing.price=0
        // console.log('error on b',b)
      }
      // console.log(a.billing.price, parseInt(a.billing.price), isNaN(a.billing.price));
      if (typeof(a.billing.price)=='string'){
          a.billing.price=parseInt(a.billing.price)
          // console.log('set a int',a);
      }
      if (typeof(b.billing.price)=='string'){
        // console.log('set b int');
          b.billing.price=parseInt(b.billing.price)
      }
      if(a.billing.price<b.billing.price){
        // console.log('a>b',a.billing.price, b.billing.price);
        return -1
      }
      if (a.billing.price>b.billing.price){
        return 1
      }
      return 0

    },
    sortDistance(a,b){
      if (a.distance==undefined){
        a.distance=999999
      }
      if (b.distance==undefined){
        b.distance=999999
      }
      if (typeof(a.distance)=='string'){
        a.distance=parseInt(a.distance)
      }
      if (typeof(b.distance)=='string'){
        b.distance=parseInt(b.distance)
      }
      if (a.distance<b.distance){
        return -1
      }
      if (a.distance>b.distance){
        return 1
      }
      return 0
    },
    sortMid(a,b){
      // console.log(Math.abs(moment(this.filters.mid).diff(moment(a.general.nextAvailability))),Math.abs(moment(this.filters.mid).diff(moment(b.general.nextAvailability))));
      if (Math.abs(moment(this.filters.mid).diff(moment(a.general.nextAvailability)))>Math.abs(moment(this.filters.mid).diff(moment(b.general.nextAvailability)))) {
          return 1
      }
      if (Math.abs(moment(this.filters.mid).diff(moment(a.general.nextAvailability)))<Math.abs(moment(this.filters.mid).diff(moment(b.general.nextAvailability)))) {
          return -1
      }
      return 0
    },
    sortNew(a,b){
      if (moment(a.lastModified).valueOf()>moment(b.lastModified).valueOf()){
        return -1
      }
      if (moment(a.lastModified).valueOf()<moment(b.lastModified).valueOf()){
        return 1
      }
      return 0
    },
    sortOld(a,b){
      if (moment(a.lastModified).valueOf()>moment(b.lastModified).valueOf()){
        return 1
      }
      if (moment(a.lastModified).valueOf()<moment(b.lastModified).valueOf()){
        return -1
      }
      return 0
    },
    setFX(curr){
      console.log('setFX');
      if (curr!="TWD" && curr!="" && curr!=undefined){
        let fxLink = 'TWD_'+curr

      // console.log('this.user currency: ', this.user.currency)
        axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=' + fxLink + '&compact=ultra&apiKey=2d6cf65af2f8557d2378')
        .then(res => {
          console.log(res.data[fxLink]);
          this.fx=res.data[fxLink]
        })
        .catch(err => {
          this.fx=1
          this.user.currency="TWD"
          console.log(err, 'cant get conversion rate')
        })
      } else {
      }


    },
    checkQuery(){
      // console.log('route: ', this.$route);
      if (this.$route.query!=undefined && this.$route.query.id!=undefined){
        console.log('post the query RTS:',this.$route.query.rts, "+ID: ",this.$route.query.id);
        axios.post('eOpens.json',{
          user:this.$route.query.id,
          rts: this.$route.query.rts,
        })
      } else {
        console.log("no query worth noting" ,this.$route.query);
      }
    },


  },
  filters: {
  },
  watch: {
    '$route'(to, from) {//watch the route for any changes from the bar.
      // SearchBarRooms('do watch route', to.query);
      // console.log('ROUTE WATCHER');
      this.setFilter(to)
      // console.||('execute the watch function!!!')

      if (this.user!=undefined && this.user.id!=undefined && this.user.id!=null){
        axios.patch('users/'+this.user.id+'/query.json?auth='+this.$store.state.auth.idToken,to.query)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    urgencyVal(){
      if (this.filters.mid!="" && this.filters.mid!=undefined && moment(this.filters.mid).isValid()){
        if (moment().add(6,"months").isBefore(moment(this.filters.mid))){
          return 3
        } else if (moment().add(4,"months").isBefore(moment(this.filters.mid))){
          return 2
        } else {
          return 1
        }
      } else {
        return 0
      }
    },
    numberRooms(){
      let t=746
      return t+Math.floor(Math.random() * Math.floor(200));
    },
    percAv(){
      if (this.numberAvNow!=0 && this.totalAv!=0){
        return Math.round(this.numberAvNow/this.totalAv*100)
      }
      else return 1
    },
    currency(){
      console.log('Set currency');
      if (this.user.currency!=undefined && this.user.currency != "") {
        this.setFX(this.user.currency)
        return this.user.currency
      } else {
        this.setFX("EUR")
        return "EUR"
      }
    },
    totalAv(){
      return this.studios.length+this.rooms.length
    },
    loading(){
      // console.log(this.loading);
      console.log('checkLoading, rooms:',this.roomsLoaded,'apts:',this.apartmentsLoaded,'studios:',this.studiosLoaded );
      if (this.roomsLoaded==true && this.apartmentsLoaded==true){
        console.log('rooms and apts');
        return false
      } else return true
      // } else if (this.studiosLoaded==true){
      //   return false
      // }{
      //   return true
      // }
    },

  },
  created() {
    console.log(this.$route.fullPath,'the path');
    this.checkQuery()

    console.log("CREATE", new Date());
    this.filtered=this.allRooms
    this.setArea(this.center)
    if (this.$route.query!=undefined){
      this.setFilter(this.$route)
      if(this.$route.query.zoom!=undefined){
        this.zoom=this.$route.query.zoom
      }
    }

  },
  components:{
    SearchBarRooms,
    Area,
    Loading,
    RoomCard,
    SearchMap,
  },
  filters:{
    month(t){
      if (moment(t).isValid()){
        return moment(t).format("MMMM")
      } else {
        return ""
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.results-col {
    padding-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
}
.box{
  border: solid 1px #ccc
}

</style>
